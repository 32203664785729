import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import useResult from "../hooks/useResult";
import useTrends from "../hooks/useTrends";
import {getUrlParam} from "../constants/helpers";
import {CLIENT_HOST} from "../constants/constants";
import {ThemeContext} from "./ThemeContext";
import ReactGA from "react-ga";

export const SearchContext = createContext({});
export const SearchContextProvider = ({ children, props }) => {
  
  const [query, setQuery] = useState( '')
  const [debounceQuery, setDebounceQuery] = useState('');
  
  const [personalityList, setPersonalityList] = useState([
    {name: 'Albert Einstein', photo:'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Albert_Einstein_Head.jpg/1920px-Albert_Einstein_Head.jpg'},
    {name: 'Barack Obama', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/President_Barack_Obama_%28cropped%29.jpg/640px-President_Barack_Obama_%28cropped%29.jpg'},
    {name: 'Bob Marley', photo: 'https://asktheai.s3.amazonaws.com/Bob+Marley.jpg'},
    {name: 'Bill Gates', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Bill_Gates_%282020%29_01.jpg/640px-Bill_Gates_%282020%29_01.jpg'},
    {name: 'Bill Clinton', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Bill_Clinton_%2830349165325%29_%28cropped%29.jpg/640px-Bill_Clinton_%2830349165325%29_%28cropped%29.jpg'},
    {name: 'Cosmo Kramer', photo: 'https://asktheai.s3.amazonaws.com/Cosmo+Kramer.jpg'},
    {name: 'Dave Chapelle', photo: 'https://asktheai.s3.amazonaws.com/Dave+Chapelle.jpg'},
    {name: 'Dwight Schrute', photo: 'https://asktheai.s3.amazonaws.com/Dwight+Schrute.jpg'},
    {name: 'Donald Trump', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Donald_Trump_official_portrait.jpg/640px-Donald_Trump_official_portrait.jpg'},
    {name: 'Elon Musk', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Elon_Musk_Royal_Society_%28crop2%29.jpg/640px-Elon_Musk_Royal_Society_%28crop2%29.jpg'},
    {name: 'Elvis Presley', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Elvis_Presley_1970.jpg/640px-Elvis_Presley_1970.jpg'},
    {name: 'Freddie Mercury', photo: 'https://asktheai.s3.amazonaws.com/Freddie+Mercury.jpg'},
    {name: 'George Constanza', photo: 'https://asktheai.s3.amazonaws.com/George+Constanza.jpg'},
    {name: 'George Orwell', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/George_Orwell_press_photo.jpg/640px-George_Orwell_press_photo.jpg'},
    {name: 'Hellen Keller', photo: 'https://asktheai.s3.amazonaws.com/Hellen+Keller.jpg'},
    {name: 'Henry Ford', photo: 'https://asktheai.s3.amazonaws.com/Henry+Ford.jpg'},
    {name: 'Hillary Clinton', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Hillary_Clinton_official_Secretary_of_State_portrait_crop.jpg/640px-Hillary_Clinton_official_Secretary_of_State_portrait_crop.jpg'},
    {name: 'Homer Simpson', photo: 'https://asktheai.s3.amazonaws.com/Homer+Simpson.png'},
    {name: 'Jeff Bezos', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Jeff_Bezos_2016_crop.jpg/640px-Jeff_Bezos_2016_crop.jpg'},
    {name: 'Jerry Seinfeld', photo: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Jerry_Seinfeld_2016_-_2.jpg'},
    {name: 'Jimi Hendrix', photo: 'https://asktheai.s3.amazonaws.com/Jimi+Hendrix.jpg'},
    {name: 'Joe Biden', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Joe_Biden_presidential_portrait.jpg/640px-Joe_Biden_presidential_portrait.jpg'},
    {name: 'Joe Rogan', photo: 'https://asktheai.s3.amazonaws.com/Joe+Rogan.jpg'},
    {name: 'John Lennon', photo: 'https://asktheai.s3.amazonaws.com/John+Lennon.jpg'},
    {name: 'Kanye West', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Kanye_West_at_the_Met_Gala_in_2019_2.png/640px-Kanye_West_at_the_Met_Gala_in_2019_2.png'},
    {name: 'Kim Kardashian', photo: 'https://i1.wp.com/wikifamouspeople.com/wp-content/uploads/2018/06/kim-kardashain.jpg?ssl=1'},
    {name: 'Kurt Cobain', photo: 'https://asktheai.s3.amazonaws.com/Kurt+Cobain.jpg'},
    {name: 'Leslie Knope', photo: 'https://asktheai.s3.amazonaws.com/Leslie+Knope.jpg'},
    {name: 'Mark Zuckerberg', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Mark_Zuckerberg_F8_2019_Keynote_%2832830578717%29_%28cropped%29.jpg/640px-Mark_Zuckerberg_F8_2019_Keynote_%2832830578717%29_%28cropped%29.jpg'},
    {name: 'Michael Jackson', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Michael_Jackson_1988_Photoshoped.jpg/640px-Michael_Jackson_1988_Photoshoped.jpg'},
    {name: 'Michael Scott', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Steve_Carell_November_2014.jpg/440px-Steve_Carell_November_2014.jpg'},
    {name: 'Mr. Beast', photo: 'https://asktheai.s3.amazonaws.com/Mr+Beast.jpg'},
    {name: 'Muhammad Ali', photo: 'https://asktheai.s3.amazonaws.com/Muhammad+Ali.jpg'},
    {name: 'Nancy Pelosi', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Official_photo_of_Speaker_Nancy_Pelosi_in_2019.jpg/640px-Official_photo_of_Speaker_Nancy_Pelosi_in_2019.jpg'},
    {name: 'Nathan Fielder', photo: 'https://asktheai.s3.amazonaws.com/Nathan+Fielder.jpg'},
    {name: 'Neil Armstrong', photo: 'https://asktheai.s3.amazonaws.com/Neil+Armstrong.jpg'},
    {name: 'PewDiePie', photo: 'https://asktheai.s3.amazonaws.com/PewDiePie.jpg'},
    {name: 'Queen Elizabeth', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Queen_Elizabeth_II_of_New_Zealand_%28cropped%29.jpg/640px-Queen_Elizabeth_II_of_New_Zealand_%28cropped%29.jpg'},
    {name: 'Rick Sanchez', photo: 'https://asktheai.s3.amazonaws.com/Rick+Sanchez.jpg'},
    {name: 'Ron Swanson', photo: 'https://asktheai.s3.amazonaws.com/Ron+Swanson.jpg'},
    {name: 'Ronald Reagan', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Ronald_Reagan_1985_presidential_portrait_%28cropped%29.jpg/640px-Ronald_Reagan_1985_presidential_portrait_%28cropped%29.jpg'},
    {name: 'Saul Goodman', photo: 'https://asktheai.s3.amazonaws.com/Saul+Goodman.jpg'},
    {name: 'Sheldon Cooper', photo: 'https://asktheai.s3.amazonaws.com/Sheldon+Cooper.jpg'},
    {name: 'SpongeBob SquarePants', photo: 'https://asktheai.s3.amazonaws.com/SpongeBob+SquarePants.jpg'},
    {name: 'Steve Jobs', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Steve_Jobs_Headshot_2010-CROP2.jpg/640px-Steve_Jobs_Headshot_2010-CROP2.jpg'},
    {name: 'Steven Hawking', photo: 'https://asktheai.s3.amazonaws.com/Steven+Hawking.jpg'},
    {name: 'Tony Stark', photo: 'https://asktheai.s3.amazonaws.com/Tony+Stark.jpg'},
    {name: 'Vladimir Putin', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Vladimir_Putin_-_2012.jpg/640px-Vladimir_Putin_-_2012.jpg'},
    {name: 'Walt Disney', photo: 'https://asktheai.s3.amazonaws.com/Walt+Disney.jpg'},
    {name: 'William Shakespeare', photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Shakespeare.jpg/640px-Shakespeare.jpg'},
  ].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }));
  const [personality, setPersonality] = useState(personalityList[Math.floor(Math.random() * personalityList.length)]);
  
  
  const [page, setPage] = useState( 0);
  const [rowsPerPage, setRowsPerPage] = useState( 10);
  const [type, setType] = useState( '');
  const [locale, setLocale] = useState('en')
  const [filterExplicit, setFilterExplicit] = useState( true);
  const [timeRange, setTimeRange] = useState( 'any')
  const oneMonthAgo = useMemo(()=>{
       let d = new Date();
       d.setMonth(d.getMonth() - 1);
       return d.toISOString().substring(0,10);
  }
  ,[]);
  const [timeRangeCustomStart, setTimeRangeCustomStart] = useState(oneMonthAgo)
  const [timeRangeCustomEnd, setTimeRangeCustomEnd] = useState(new Date().toISOString().substring(0,10))
  const [indexCount, setIndexCount] = useState(0)
  const [entityCount, setEntityCount] = useState(0)
  const [trendingList, setTrendingList] = useState([])
  const [searchResults, setSearchResults] = useState()
  const [totalSearchResults, setTotalSearchResults] = useState(-1)
  const [showExtendedCategories, setShowExtendedCategories] = useState( false)
  const originalPageTitle = useMemo(()=>document.title,[])
  const { darkMode } = useContext(ThemeContext);
  const directLink = useMemo(()=>{
    if(timeRange==='custom'){
      return `${CLIENT_HOST}/?q=${debounceQuery}&personality=${personality.name}&c=${type}&p=${page}&s=${rowsPerPage}&l=${locale}&r=${timeRange}&b=${timeRangeCustomStart}&e=${timeRangeCustomEnd}&m=${Number(filterExplicit)}&x=${Number(showExtendedCategories)}&t=${darkMode ? 'dark' : 'light'}`
    }else{
      return `${CLIENT_HOST}/?q=${debounceQuery}&personality=${personality.name}&t=${darkMode ? 'dark' : 'light'}`
    }
     },[debounceQuery,type,page,rowsPerPage,locale,timeRange,timeRangeCustomStart,timeRangeCustomEnd,filterExplicit,showExtendedCategories,darkMode])
  
  //onload, over-ride defaults
  
  useEffect(() => {
    if (!window.location.hostname.includes("localhost")) { /* Production Only */
      const adjustedURL = directLink.split(/\.ai\/?|\.dev\/?/);
      ReactGA.initialize('UA-154880-12');
      ReactGA.pageview(adjustedURL[1]);
    }
  },[directLink])
  useEffect(() => {
    if(getUrlParam('q')){
      setQuery(getUrlParam('q'))
    }
    if(getUrlParam('s')){
      setRowsPerPage(Number(getUrlParam('s')))
    }
    if(getUrlParam('p')){
      setPage(Number(getUrlParam('p')))
    }
    if(getUrlParam('c')){
      setType(getUrlParam('c'))
    }
    if(getUrlParam('l')){
      setLocale(getUrlParam('l'))
    }
    if(getUrlParam('m')){
      setFilterExplicit(Boolean(getUrlParam('m')))
    }
    if(getUrlParam('r')){
      setTimeRange(getUrlParam('r'))
    }
    if(getUrlParam('x') && getUrlParam('x') === '1'){
      setShowExtendedCategories(true)
    }
    if(getUrlParam('b')){
      setTimeRangeCustomStart(getUrlParam('b'))
    }
    if(getUrlParam('e')){
      setTimeRangeCustomEnd(getUrlParam('e'))
    }
    
  }, [setQuery, setRowsPerPage,setPage,setType,setLocale,setFilterExplicit,setTimeRange,setShowExtendedCategories,setTimeRangeCustomStart,setTimeRangeCustomEnd]);
  
  
  
  useEffect(() => {
      //setQuery('');
  }, [personality]);
  
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setDebounceQuery(query);
        document.title = personality ?  personality.name + " - AskThe.AI" : originalPageTitle
    }, 1000);
    
    return () => {
      clearTimeout(timeOutId);
    };
  }, [query, originalPageTitle, personality]);
  
  const trendData = []
  const trendsLoading = false
  //const { data: trendData, isLoading: trendsLoading } = useTrends();
  const { data: searchResultsObj, isLoading: resultsLoading } = useResult(debounceQuery, personality, page, rowsPerPage, type, locale, filterExplicit, timeRange);
  
  useEffect(() => {
   if(searchResultsObj){
     setSearchResults(searchResultsObj.response.choices)
     setTotalSearchResults(Number(searchResultsObj.total_results))
   }
  }, [searchResultsObj, resultsLoading]);
  
  useEffect(() => {
   if(trendData){
     setIndexCount(trendData.indexCount)
     setEntityCount(trendData.entityCount)
     setTrendingList(trendData.data)
   }
  }, [trendData]);
  
  const forceSearch = useCallback(value => {
    setDebounceQuery(query);
  },[query]);
  
  return (
    <SearchContext.Provider
      value={{
        actions: {
          setQuery,
          setTimeRange,
          setLocale,
          setFilterExplicit,
          forceSearch,
          setTrendingList,
          setPage,
          setType,
          setRowsPerPage,
          setTimeRangeCustomStart,
          setTimeRangeCustomEnd,
          setShowExtendedCategories,
          setPersonalityList,
          setPersonality
        },
        state: {
          personality,
          personalityList,
          query,
          debounceQuery,
          timeRange,
          locale,
          filterExplicit,
          timeRangeCustomStart,
          timeRangeCustomEnd,
          trendingList,
          searchResults,
          totalSearchResults,
          resultsLoading,
          trendsLoading,
          indexCount,
          entityCount,
          page,
          type,
          rowsPerPage,
          directLink,
          showExtendedCategories
        },
      }}
      {...props}
    >
      {children}
    </SearchContext.Provider>
  );
};
